import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
//import { authRoles } from '../../auth/authRoles'
const Consent = Loadable(lazy(() => import('./Consents')))
const Provider = Loadable(lazy(() => import('./Providers')))
const AdvancedCareplan = Loadable(lazy(() => import('./Advancedcareplanning')))
const PrivacyTabs=Loadable(lazy(() => import('./privacyTabs')))
const PrivacyRoutes = [
    {
        path: '/consents',
        element: <Consent />,
        //auth: authRoles.admin,
    },
    {
        path: '/providers',
        element: <Provider />,
        // auth: authRoles.admin,
    },
    {
        path: '/ac-planning',
        element: <AdvancedCareplan />,
        // auth: authRoles.admin,
    },
    {
        path: '/privacy',
        element: <PrivacyTabs />,
        // auth: authRoles.admin,
    },
   
]

export default PrivacyRoutes