import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
//import { authRoles } from '../../auth/authRoles'
const Forms = Loadable(lazy(() => import('./Forms')))
const VisitsTabs = Loadable(lazy(() => import('./visitsTabs')))
const VisitsRoutes = [
    
    {
        path: '/forms',
        element: <Forms />,
        //auth: authRoles.admin,
    },
    {
        path: '/visits',
        element: <VisitsTabs />,
        //auth: authRoles.admin,
    },
   
]

export default VisitsRoutes
