export const navigations = [
    {
        label: 'PATIENT',
        type: 'label',
    },
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: 'dashboard',
    },
    {
        name: 'My Appointments',
        path: '/Myappointments',
        icon: 'schedule',
    },
    // {
    //     name: 'Forms',
    //     path: '/forms',
    //     icon: 'dynamic_form',
    // },
    {
        name: 'Demographics',
        path: '/demographics',
        icon: 'texture',
    },
    // {
    //     name: 'Visits',
    //     path: '/visits',
    //     icon: 'timeline',
    // },
    {
        name: 'my pill box',
        path: '/medications',
        icon: 'vaccines',
    },
    // {
    //     name: 'Visits',
    //     icon: 'timeline',

    //     children: [
    //         {
    //             name: 'Encounter',
    //             path: '/timeline',
    //             iconText: 'TL',
    //         },
    //         {
    //             name: 'Appointments',
    //             path: '/appointment',
    //             iconText: 'APT',
    //         },
    //         // {
    //         //     name: 'Advanced care planning',
    //         //     path: '/ac-planning',
    //         //     iconText: 'ACP',
    //         // },

    //     ],
    // },
    {
        name: 'Patient 360',
        path: '/patient360',
        icon: 'ac_unit',
    },

    //  {
    //     name: 'Patient 360',
    //     icon: 'ac_unit',

    //     children: [

    //         {
    //             name: 'Vitals',
    //             path: '/observation',
    //             iconText: 'OB',
    //         },
    //         {
    //             name: 'Allergies',
    //             path: '/allergies',
    //             iconText: 'AG',
    //         },
    //         {
    //             name: 'Immunizations',
    //             path: '/immunizations',
    //             iconText: 'IM',
    //         },
    //         {
    //             name: 'Problems',
    //             path: '/problems',
    //             iconText: 'PB',
    //         },
    //         {
    //             //name: 'Medications',
    //             name:'my pill box',
    //             path: '/medications',
    //             iconText: 'MD',
    //         },
    //         // {
    //         //     name: 'MedicationRequest',
    //         //     path: '/medicationrequest',
    //         //     iconText: 'MD',
    //         // },
    //         {
    //             name: 'Labs',
    //             path: '/labs',
    //             iconText: 'LB',
    //         },
    //         {
    //             name: 'Radiology',
    //             path: '/radiology',
    //             iconText: 'RD',
    //         },
    //         {
    //             name: 'Pathology',
    //             path: '/pathology',
    //             iconText: 'MD',
    //         },
    //         // {
    //         //     name: 'Disease Management',
    //         //     path: '/diseasemanagement',
    //         //     iconText: 'DM',
    //         // },

    //     ],

    // },
    // {
    //     name: 'Disease Management',
    //     icon: 'opacity',
    //    // auth: authRoles.Case,
    //     children: [

    //         {
    //             name: 'Preventative Care',
    //             iconText: 'PTC',
    //             path: '/preventativecare',
    //         },
    //         {
    //             name: 'Diabetes Management',
    //             iconText: 'DM',
    //             path: '/diseasemanagement',
    //         },
    //     ],
    // },

    {
        name: 'Disease Management',
        path: '/diseasemgmt',
        icon: 'opacity',
    },
    {
        name: 'Privacy',
        path: '/privacy',
        icon: 'health_and_safety',
    },
    // {
    //     name: 'Privacy',
    //     icon: 'health_and_safety',

    //     children: [
    //         {
    //             name: 'Providers',
    //             path: '/providers',
    //             iconText: 'PVR',
    //         },
    //         {
    //             name: 'Consents',
    //             path: '/consents',
    //             iconText: 'CTS',
    //         },
    //         // {
    //         //     name: 'Advanced care planning',
    //         //     path: '/ac-planning',
    //         //     iconText: 'ACP',
    //         // },

    //     ],
    // },
    // {
    //     name: 'Health information',
    //     icon: 'heart_broken',

    //     children: [

    //         {
    //             name: 'Patient Anywhere',
    //             path: '/health-information',
    //             iconText: 'HIF',
    //         },
    //         {
    //             name: 'Medical documets',
    //             path: '/medical-documnets',
    //             iconText: 'MDS',
    //         },
    //         {
    //             name: 'Assessment',
    //             path: '/forms',
    //             iconText: 'FMS',
    //         },
    //         {
    //             name: 'Advanced care planning',
    //             path: '/ac-planning',
    //             iconText: 'ACP',
    //         },
    //         // {
    //         //     name: 'Appointments',
    //         //     path: '/appointment',
    //         //     iconText: 'APT',
    //         // },
    //         // {
    //         //     name: 'Bills',
    //         //     path: '/bills',
    //         //     iconText: 'BIL',
    //         // },

    //     ],
    // },
    // {
    //     name: 'Communications',
    //     icon: 'comment',

    //     children: [
    //         {
    //             name: 'Email',
    //             path: '/email',
    //             iconText: 'em',
    //         },
    //         // {
    //         //     name: 'Messages',
    //         //     path: '/messages',
    //         //     iconText: 'MS',
    //         // },
    //         {
    //             name:"Contacts",
    //             path: '/contacts',
    //             iconText: 'Ct',
    //         }
    //         // {
    //         //     name: 'Video',
    //         //     path: '/video',
    //         //     iconText: 'vd',
    //         // },
    //     ],
    // },
    {
        name: 'Health information',
        path: '/healthinfo',
        icon: 'heart_broken',
    },
    {
        name: 'Communications',
        path: '/communication',
        icon: 'comment',
    },
    {
        name: 'Bills',
        path: '/bills',
        icon: 'account_balance_wallet',
    },

    // {
    //     label: 'PAGES',
    //     type: 'label',
    // },
    // {
    //     name: 'Session/Auth',
    //     icon: 'security',
    //     children: [
    //         {
    //             name: 'Sign in',
    //             iconText: 'SI',
    //             path: '/session/signin',
    //         },
    //         {
    //             name: 'Sign up',
    //             iconText: 'SU',
    //             path: '/session/signup',
    //         },
    //         {
    //             name: 'Forgot Password',
    //             iconText: 'FP',
    //             path: '/session/forgot-password',
    //         },
    //         {
    //             name: 'Error',
    //             iconText: '404',
    //             path: '/session/404',
    //         },
    //     ],
    // },
    // {
    //     label: 'Components',
    //     type: 'label',
    // },

    // {
    //     name: 'Charts',
    //     icon: 'trending_up',

    //     children: [
    //         {
    //             name: 'Echarts',
    //             path: '/charts/echarts',
    //             iconText: 'E',
    //         },
    //     ],
    // },
    // {
    //     name: 'Documentation',
    //     icon: 'launch',
    //     type: 'extLink',
    //     path: 'http://demos.ui-lib.com/matx-react-doc/',
    // },
]
