import * as actionTypes from '../actions/types'
const initialState = {
    oauthUser: {
        sbmUserId: null,
        email: null,
        name: null,
        roles: null,
    },
    user: null,
    pendingUsers: [],
    rejectedUsers: [],
}

const saveOauthUser = (state, action) => {
    const user = { ...state.oauthUser }
    user.sbmUserId = action.sbmUserId
    user.email = action.email
    user.name = action.name
    user.roles = action.roles
    state.oauthUser = user
    localStorage.setItem('oauth', JSON.stringify(user))
    localStorage.setItem('org', JSON.stringify(user.sbmUserId))
    return state
}

export default (state = initialState, action) => {
    state = Object.assign({}, state)

    switch (action.type) {
        case actionTypes.SAVE_OAUTH_USER:
            state = saveOauthUser(state, action)
            break
        case actionTypes.SAVE_SANDBOX_USER:
            state.user = action.user
            break
        case actionTypes.APP_RESET_STATE:
            state = initialState
            break
        // default: {
        //     return state
        // }
        // case 'persist/REHYDRATE':
        //   state = action.payload ? action.payload.users : state;
        //   break;
    }

    return state
}
