export const AUTHORIZATION_START = 'AUTHORIZATION_START';
export const AUTHORIZATION_SUCCESS = 'AUTHORIZATION_SUCCESS';
export const AUTHORIZATION_FAIL = 'AUTHORIZATION_FAIL';
export const SAVE_OAUTH_USER = 'SAVE_OAUTH_USER';
export const SAVE_SANDBOX_USER = 'SAVE_SANDBOX_USER';
export const APP_RESET_STATE = 'APP_RESET_STATE';
export const FHIR_LOGIN = 'FHIR_LOGIN';

export const FHIR_LOGIN_SUCCESS = 'FHIR_LOGIN_SUCCESS';
export const FHIR_LOGIN_FAIL = 'FHIR_LOGIN_FAIL';
export const FHIR_CLEAR_TOKEN = 'FHIR_CLEAR_TOKEN';
export const FHIR_INIT = 'FHIR_INIT';
export const FHIR_CLIENT = 'FHIR_CLIENT';
export const FHIR_HSPC_AUTHORIZED = 'FHIR_HSPC_AUTHORIZED';
export const FHIR_VERSION = 'FHIR_VERSION';
export const SAVE_ENDPOINT_INDEX = 'SAVE_ENDPOINT_INDEX';
export const SET_ERROR_TO_SHOW = 'SET_ERROR_TO_SHOW';
export const SANDBOX_LOAD_START='SANDBOX_LOAD_START';
export const SANDBOX_LOAD_SUCCESS='SANDBOX_LOAD_SUCCESS';
export const SANDBOX_LOAD_FAIL='SANDBOX_LOAD_FAIL';


export const HOSPITAL_SELECT_START='HOSPITAL_SELECT_START';
export const HOSPITAL_SELECT_SUCCESS='HOSPITAL_SELECT_SUCCESS';
export const HOSPITAL_SELECT_FAIL='HOSPITAL_SELECT_FAIL';