import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const Schedule = Loadable(lazy(() => import('./schedule')))
const schuduleRoutes = [
   
    {
        path: '/Myappointments',
        element: <Schedule />,
        // auth: authRoles.admin,
    },

]

export default schuduleRoutes
