import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const Analytics = Loadable(lazy(() => import('./Analytics')))
const Timeline = Loadable(lazy(() => import('./Timeline')))
const DemoGraphics = Loadable(lazy(() => import('./demographics')))
const SelfChecks = Loadable(lazy(() => import('./SelfCheck')))
const dashboardRoutes = [
    {
        path: '/dashboard',
        element: <Analytics />,
        auth: authRoles.admin,
    },
    {
        path: '/timeline',
        element: <Timeline />,
       // auth: authRoles.admin,
    },
    {
        path: '/demographics',
        element: <DemoGraphics />,
       // auth: authRoles.admin,
    },
    {
        path: '/selfcheck',
        element: <SelfChecks />,
       // auth: authRoles.admin,
    },
    
]

export default dashboardRoutes
