import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import NavigationReducer from './NavigationReducer'
import Users from './users'
import Authorize from './authorize';
import Sandbox from './sandbox';
import Hsptlrole from './hsptlrole'
import getUserAppointmentReducer from './getUserAppointmentReducer'
///cardic risk

import defaultSetReducer from './services/utils/default-set-reducer';

import { fhirPatientData, fhirObservationData, fhirEncounterData, fhirConditionData, fhirMedReqData } from  './services/fhir/FhirReducers'
import { preventativeCare } from './services/PreventativeCareReducers';
import { envFactorsData } from './services/Environment/EnvReducers';

import { measurementState } from './services/MeasurementReducers';

import {externalState} from './services/ExternalReducers';

import {SET_PAST_DATE} from '../actions/actions'

export const pastDate = defaultSetReducer('pastDate', new Date(2012, 7, 15));

//export const externalState = defaultSetReducer('externalState', {smoking:[false, false, false]})

export function setPastDate(state = {}, action) {
	switch(action.type) {
		case SET_PAST_DATE:
			return Object.assign({}, state, {payload: action.pastDate})
		default:
			return state;
	}
}

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
    authorize :Authorize,
    user :Users,
    sandbox:Sandbox,
    Hsptlrole:Hsptlrole,
    //
    fhirPatientData,
    fhirObservationData,
    fhirEncounterData,
    fhirConditionData,
    fhirMedReqData,
    envFactorsData,
    measurementState,
    pastDate,
    externalState,
    preventativeCare,
    getUserAppointmentReducer,
})

export default RootReducer

/////




