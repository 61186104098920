import * as actionTypes from '../actions/types';
//import {REHYDRATE} from 'redux-persist/lib/constants';
let initialState ={
    token: null,
    loading: false,
    error: null,
    hspcAuthorized : false,
    fhirClient: null,
    fhirVersion: null,
}
//export default 
 const authorize=(state = initialState, action) => {
    state = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.FHIR_LOGIN:
            state.error = null;
            state.loading = true;
            break;
        case actionTypes.FHIR_HSPC_AUTHORIZED:
            state.hspcAuthorized = true;
            break;
        case actionTypes.FHIR_CLIENT:
            state.fhirClient = action.fhirClient;
            break;
        case actionTypes.FHIR_VERSION:
            state.fhirVersion = action.fhirVersion;
            break;
        case actionTypes.APP_RESET_STATE:
            state = initialState;
            break;
        //   case REHYDRATE:
        //         state = action.payload ? action.payload.fhirauth : state;
        //      break;
        // case "persist/REHYDRATE":
        //     state = action.payload ? action.payload.fhirauth : state;
        //     break;
    }
    return state;
};
export default authorize