import useAuth from 'app/hooks/useAuth'
import { flat } from 'app/utils/utils'
import React, {
    useState,
    useEffect,
    //useContext
} from 'react'
import { Navigate, useLocation } from 'react-router-dom'
//import AppContext from '../contexts/AppContext'
import { AllPages } from '../routes/routes'
import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
const getUserRoleAuthStatus = (pathname, user, routes) => {
    if (!user) {
        return false
    }
    const matched = routes.find((r) => r.path === pathname)
    var a = JSON.parse(localStorage.getItem('hsptl'))
  
    const authenticated =
        matched && matched.auth && matched.auth.length
            ? //? matched.auth.includes(user.role)
              matched.auth.includes(
                 a==null || a.names == null ? <Navigate to="/" /> : a && a.names
              )
            : true
    // console.log(matched, user)
    
    return authenticated
}

const AuthGuard = ({ children, ...props }) => {
    const { isAuthenticated, user } = useAuth()

    // return <>{isAuthenticated ? children : <Navigate to="/session/signin" />}</>

    const [previouseRoute, setPreviousRoute] = useState(null)
    const { pathname } = useLocation()
    const routes = flat(AllPages())

    //console.log(user)

    // const isUserRoleAuthenticated = getUserRoleAuthStatus(
    //     pathname,
    //     user,
    //     routes
    // )
    const isUserRoleAuthenticated = getUserRoleAuthStatus(
        pathname,
        JSON.stringify(props.user),
        routes
    )
    let authenticated = isAuthenticated && isUserRoleAuthenticated

    // IF YOU NEED ROLE BASED AUTHENTICATION,
    // UNCOMMENT ABOVE TWO LINES, getUserRoleAuthStatus METHOD AND user VARIABLE
    // AND COMMENT OUT BELOW LINE

    // let authenticated = isAuthenticated

    useEffect(() => {
        if (previouseRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previouseRoute])
    {
        console.log(props.user.name || '')
    }
    if (!authenticated) return <>{children}</>
    else {
        return (
            <Navigate
                // to="/session/signin"
                to="/start"
                state={{ redirectUrl: previouseRoute }}
            />
            // <Redirect
            //     to={{
            //         pathname: '/session/signin',
            //         state: { redirectUrl: previouseRoute },
            //     }}
            // />
        )
    }
}

//export default AuthGuard

const mapStateToProps = (state) => {
    return {
        user: state.user.oauthUser,
        Hsptlrole: state.Hsptlrole.hsptl,
    }
}

export default connect(mapStateToProps)(AuthGuard)
