import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
//import { authRoles } from '../../auth/authRoles'
const Appointment = Loadable(lazy(() => import('./Appointments')))
const Healthinfo = Loadable(lazy(() => import('./IOT')))
const Medicaldocumnets = Loadable(lazy(() => import('./Medicaldocumnets')))
const Bills = Loadable(lazy(() => import('./Bills')))
const HealthinfoTabs = Loadable(lazy(() => import('./HealthinfoTabs')))
const HealthRoutes = [
    {
        path: '/appointment',
        element: <Appointment />,
        //auth: authRoles.admin,
    },
    {
        path: '/health-information',
        element: <Healthinfo />,
        // auth: authRoles.admin,
    },
    {
        path: '/medical-documnets',
        element: <Medicaldocumnets />,
        // auth: authRoles.admin,
    },
    {
        path: '/bills',
        element: <Bills />,
        // auth: authRoles.admin,
    },
    {
        path: '/healthinfo',
        element: <HealthinfoTabs />,
        // auth: authRoles.admin,
    },
    
]

export default HealthRoutes
