import 'whatwg-fetch'
import { getURL, getInsecureURL } from '../smart_setup';
import { sortMeasurementsFromClient } from '../general_utils';

//utils
import { sortByDate } from '../general_utils';
// get patient data
var mkFhir = require('fhir.js');
export const FETCH_PATIENT_REQUEST = 'FETCH_PATIENT_REQUEST';
export const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS';
export const FETCH_PATIENT_FAILURE = 'FETCH_PATIENT_FAILURE';

export const requestAllPatientData = patientID => ({
  type: FETCH_PATIENT_REQUEST,
  patientID
});

export const receiveAllPatientData = (patientID, json) => ({
  type: FETCH_PATIENT_SUCCESS,
  patientID,
  ptData:  [
    {
          "fullUrl": "http://hapi.fhir.org/baseDstu3/Patient/182296",
          "resource": {
            "resourceType": "Patient",
            "id": "182296",
            "meta": {
              "versionId": "4",
              "lastUpdated": "2017-07-28T11:29:51.033-04:00",
              "profile": [
                "http://standardhealthrecord.org/fhir/StructureDefinition/shr-demographics-PersonOfRecord"
              ]
            },
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Generated by <a href=\"https://github.com/synthetichealth/synthea\">Synthea</a>. Version identifier: a5e58f51c35bef8d0c9c3687396e9a35b3356076</div>"
            },
            "extension": [
              {
                "url": "http://hl7.org/fhir/us/core/StructureDefinition/us-core-race",
                "valueCodeableConcept": {
                  "coding": [
                    {
                      "system": "http://hl7.org/fhir/v3/Race",
                      "code": "2054-5",
                      "display": "Black"
                    }
                  ],
                  "text": "race"
                }
              },
              {
                "url": "http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity",
                "valueCodeableConcept": {
                  "coding": [
                    {
                      "system": "http://hl7.org/fhir/v3/Ethnicity",
                      "code": "2186-5",
                      "display": "Nonhispanic"
                    }
                  ],
                  "text": "ethnicity"
                }
              },
              {
                "url": "http://hl7.org/fhir/StructureDefinition/birthPlace",
                "valueAddress": {
                  "city": "Rockport",
                  "state": "MA",
                  "country": "US"
                }
              },
              {
                "url": "http://hl7.org/fhir/StructureDefinition/patient-mothersMaidenName",
                "valueString": "Gregory700 Boyer318"
              },
              {
                "url": "http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex",
                "valueCode": "F"
              },
              {
                "url": "http://hl7.org/fhir/StructureDefinition/patient-interpreterRequired",
                "valueBoolean": false
              },
              {
                "url": "http://synthetichealth.github.io/synthea/gbd-daly",
                "valueDecimal": 4.279900724923035
              },
              {
                "url": "http://synthetichealth.github.io/synthea/gbd-qaly",
                "valueDecimal": 5.0924962060066195
              },
              {
                "url": "http://standardhealthrecord.org/fhir/StructureDefinition/shr-actor-FictionalPerson-extension",
                "valueBoolean": true
              },
              {
                "url": "http://standardhealthrecord.org/fhir/StructureDefinition/shr-demographics-FathersName-extension",
                "valueHumanName": {
                  "text": "Joelle552 Konopelski407"
                }
              },
              {
                "url": "http://standardhealthrecord.org/fhir/StructureDefinition/shr-demographics-SocialSecurityNumber-extension",
                "valueString": "999-88-1031"
              }
            ],
            "identifier": [
              {
                "system": "https://github.com/synthetichealth/synthea",
                "value": "612fb6c3-bd62-47ea-b130-b9ecec6236b2"
              },
              {
                "type": {
                  "coding": [
                    {
                      "system": "http://hl7.org/fhir/identifier-type",
                      "code": "SB"
                    }
                  ]
                },
                "system": "http://hl7.org/fhir/sid/us-ssn",
                "value": "999881031"
              },
              {
                "type": {
                  "coding": [
                    {
                      "system": "http://hl7.org/fhir/v2/0203",
                      "code": "DL"
                    }
                  ]
                },
                "system": "urn:oid:2.16.840.1.113883.4.3.25",
                "value": "S99987213"
              },
              {
                "type": {
                  "coding": [
                    {
                      "system": "http://hl7.org/fhir/v2/0203",
                      "code": "PPN"
                    }
                  ]
                },
                "system": "http://standardhealthrecord.org/fhir/StructureDefinition/passportNumber",
                "value": "X28691332X"
              },
              {
                "type": {
                  "coding": [
                    {
                      "system": "http://hl7.org/fhir/v2/0203",
                      "code": "MR"
                    }
                  ]
                },
                "system": "http://hospital.smarthealthit.org",
                "value": "612fb6c3-bd62-47ea-b130-b9ecec6236b2"
              }
            ],
            "name": [
              {
                "use": "official",
                "family": "Murphy",
                "given": [
                  "Jasper"
                ],
                "prefix": [
                  "Mrs."
                ]
              },
              {
                "use": "maiden",
                "family": "Konopelski",
                "given": [
                  "Jasper"
                ]
              }
            ],
            "telecom": [
              {
                "system": "phone",
                "value": "(853) 167-2216",
                "use": "home"
              }
            ],
            "gender": "female",
            "birthDate": "1945-07-28",
            "address": [
              {
                "extension": [
                  {
                    "url": "http://hl7.org/fhir/StructureDefinition/geolocation",
                    "extension": [
                      {
                        "url": "latitude",
                        "valueDecimal": 42.63194367451194
                      },
                      {
                        "url": "longitude",
                        "valueDecimal": -71.6159958906655
                      }
                    ]
                  }
                ],
                "line": [
                  "4757 Tristin Street"
                ],
                "city": "Pepperell",
                "state": "MA",
                "postalCode": "01463",
                "country": "US"
              }
            ],
            "maritalStatus": {
              "coding": [
                {
                  "system": "http://hl7.org/fhir/v3/MaritalStatus",
                  "code": "M"
                }
              ],
              "text": "M"
            },
            "multipleBirthBoolean": false,
            "communication": [
              {
                "language": {
                  "coding": [
                    {
                      "system": "http://hl7.org/fhir/ValueSet/languages",
                      "code": "es",
                      "display": "Spanish"
                    }
                  ]
                }
              }
            ]
          },
          "search": {
            "mode": "match"
          }
        }],
  
  //json.entry[0].resource,
  receivedAt: Date.now()
});

export const failAllPatientData = patientID => ({
  type: FETCH_PATIENT_FAILURE,
  patientID,
  error: 'oops',
  receivedAt: Date.now()

});

// http://fhirtest.uhn.ca/baseDstu3/Patient
export function fetchAllPatientData(patientID) {
  return (dispatch) => {
    dispatch(requestAllPatientData(patientID));
    const baseUrl = getURL();
console.log(baseUrl)
    return fetch(baseUrl + '/Patient?_id=' + patientID)
      .then(
        response => {
          if(!response.ok){
            dispatch(failAllPatientData(patientID));
            return Promise.resolve();            
          }
          return response.json();
        },
        error => {
          console.warn('An error occured fetching the patient info :(', error)
          dispatch(failAllPatientData(patientID));
          return Promise.resolve();

        }
      )
      .then(json => {
        if(!json || !json.entry){
          dispatch(failAllPatientData(patientID));
          return Promise.resolve();
        }
        dispatch(receiveAllPatientData(patientID, json))
      });
   };
}

function shouldFetchAllPatientData(state, patientID) {
  const data = state.fhirPatientData.ptData;
  if (!data) {
    return true;
  } else if (state.fhirPatientData.isFetchingAllPatientData) {
    return false;
  } else {
  }
}

export function fetchAllPatientDataIfNeeded(patientID) {
  return (dispatch, getState) => {
    if (shouldFetchAllPatientData(getState(), patientID)) {
      // Dispatch a thunk from thunk!
      return dispatch(fetchAllPatientData(patientID));
    }
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  };
}

// we are getting the most recent visit of the patient
export const FETCH_LAST_VISIT_DATE_REQUEST = 'FETCH_LAST_VISIT_DATE_REQUEST';
export const FETCH_LAST_VISIT_DATE_SUCCESS = 'FETCH_LAST_VISIT_DATE_SUCCESS';

export const requestLastVisitDate = patientID => ({
  type: FETCH_LAST_VISIT_DATE_REQUEST,
  patientID
});

export const receiveLastVisitDate = (patientID, data) => ({
  type: FETCH_LAST_VISIT_DATE_SUCCESS,
  patientID,
  lastVisit: data,
  receivedAt: Date.now()
});


export function fetchMostRecentVisitDate(patientID) {
  return (dispatch) => {
    dispatch(requestLastVisitDate(patientID));
    const baseUrl = getURL();

    return fetch(baseUrl + '/Observation?subject=' + patientID + '&_sort=-date&_count=1&date:missing=false')
      .then(
        response => response.json(),
        error => console.error('An error occured.', error)
      )
      .then((json) => {
        if(!json || !json.entry){
          return Promise.resolve();
        }
        let recentDate = json.entry[0].resource.effectiveDateTime;
        dispatch(receiveLastVisitDate(patientID, recentDate));
      }
      );
  };



}


// get most recent encounter information
export const FETCH_RECENT_ENCOUNTER_REQUEST = 'FETCH_RECENT_ENCOUNTER_REQUEST';
export const FETCH_RECENT_ENCOUNTER_SUCCESS = 'FETCH_RECENT_ENCOUNTER_SUCCESS';
export const FETCH_RECENT_ENCOUNTER_FAILURE = 'FETCH_RECENT_ENCOUNTER_FAILURE';

export const requestMostRecentEcounterData = patientID => ({
  type: FETCH_RECENT_ENCOUNTER_REQUEST,
  patientID
});

export const receiveMostRecentEncounterData = (patientID, json) => ({
  type: FETCH_RECENT_ENCOUNTER_SUCCESS,
  patientID,
  lastVisit: json.entry[0].resource.period.end,
  receivedAt: Date.now()
});

export const failMostRecentEncounterData = patientID => ({
  type: FETCH_RECENT_ENCOUNTER_FAILURE,
  patientID,
  error: 'oops'
});

// https://fhirtest.uhn.ca/baseDstu3/Encounter?subject=182296&_count=1&_sort=date
export function fetchMostRecentEncounterData(patientID) {
  return (dispatch) => {
    dispatch(requestMostRecentEcounterData(patientID));
    const baseUrl = getURL();
//console.log(baseUrl)
    return fetch(baseUrl + '/Encounter?subject=' + patientID + '&_count=1&_sort=-date')
      .then(
        response => response.json(),
        error => console.error('An error occured.', error)
      )
      .then(json =>
        dispatch(receiveMostRecentEncounterData(patientID, json))
      );
  };
}

// get condition data
export const FETCH_ALL_CONDITION_REQUEST = 'FETCH_ALL_CONDITION_REQUEST';
export const FETCH_ALL_CONDITION_SUCCESS = 'FETCH_ALL_CONDITION_SUCCESS';
export const FETCH_ALL_CONDITION_FAILURE = 'FETCH_ALL_CONDITION_FAILURE';

export const requestAllConditionData = patientID => ({
  type: FETCH_ALL_CONDITION_REQUEST,
  patientID
});

export const receiveAllConditionData = (patientID, json) => ({
  type: FETCH_ALL_CONDITION_SUCCESS,
  patientID,
  allCondData: json,
  receivedAt: Date.now()
});

export const failAllConditionData = () => ({
  type: FETCH_ALL_CONDITION_FAILURE,
  error: 'oops'
});

// https://fhirtest.uhn.ca/baseDstu3/Encounter?subject=182296&_count=1&_sort=date
export function fetchAllConditionData(patientID) {
  return (dispatch) => {
    dispatch(requestAllConditionData(patientID));

    // var mkFhir = require('fhir.js');
    var client = mkFhir({
      baseUrl: getInsecureURL()
    });

    //sort by code
    client
      .fetchAll({type: 'Condition', query: {'subject':patientID}})
      .then(function(res){
        var bundle = res;
        dispatch(receiveAllConditionData(patientID, bundle));
      })
      .catch(function(res){
        if (res.status){
          console.log("error1", res.status)
        }

        //Errors
        if (res.message){
          console.log("error2", res.message)
        }
        dispatch(failAllConditionData());
        return Promise.resolve();

      });


  };
}

// get medication request data
export const FETCH_ALL_MEDREQ_REQUEST = 'FETCH_ALL_MEDREQ_REQUEST';
export const FETCH_ALL_MEDREQ_SUCCESS = 'FETCH_ALL_MEDREQ_SUCCESS';
export const FETCH_ALL_MEDREQ_FAILURE = 'FETCH_ALL_MEDREQ_FAILURE';

export const requestAllMedReqData = patientID => ({
  type: FETCH_ALL_MEDREQ_REQUEST,
  patientID
});

export const receiveAllMedReqData = (patientID, data) => ({
  type: FETCH_ALL_MEDREQ_SUCCESS,
  patientID,
  allMedReqData: data,
  receivedAt: Date.now()
});

export const failAllMedReqData = () => ({
  type: FETCH_ALL_MEDREQ_FAILURE,
  error: 'oops'
});

// https://fhirtest.uhn.ca/baseDstu3/Encounter?subject=182296&_count=1&_sort=date
export function fetchAllMedReqData(patientID) {
  return (dispatch) => {
    dispatch(requestAllMedReqData(patientID));
   const baseUrl = getURL();
    //get the most recent data
    console.log(baseUrl)
    return fetch(baseUrl + '/MedicationRequest?subject=' + patientID)
      .then(
        response => response.json(),
        error => console.error('An error occured.', error)
      )
      .then(function(json){
          if(!json || !json.entry){
            dispatch(failAllMedReqData());
            return Promise.resolve();
          }
          else {
          }
        dispatch(receiveAllMedReqData(patientID, json.entry));
        } 
      );


  };
}


//get observations data

//get most recent observations by code

// https://fhirtest.uhn.ca/baseDstu3/Observation?subject=182296&_count=1&_sort=date&code=2085-9
export const FETCH_RECENT_OBSERVATION_REQUEST = 'FETCH_RECENT_OBSERVATION_REQUEST';
export const FETCH_RECENT_OBSERVATION_SUCCESS = 'FETCH_RECENT_OBSERVATION_SUCCESS';


export const requestMostRecentObsByCode = (patientID, code) => ({
  type: FETCH_RECENT_OBSERVATION_REQUEST,
  patientID,
  code
});

export const receiveMostRecentObsByCode = (patientID, code, data) => ({
  type: FETCH_RECENT_OBSERVATION_SUCCESS,
  patientID,
  code,
  recent_obs: data,
  receivedAt: Date.now()
});

export function fetchMostRecentObsByCode(patientID, code, subcode = null) {
  return (dispatch, getState) => {
    //TODO include a shouldFetchMostRecentObs after you figure out why it doens't work in the all measurement case
    dispatch(requestMostRecentObsByCode(patientID, code));
    const baseUrl = getURL();
    //get the most recent data
    return fetch(baseUrl + '/Observation?subject=' + patientID + '&code=' + code + '&_count=1&_sort=-date')
      .then(
        response => response.json(),
        error => console.error('An error occured.', error)
      )
      .then(function(json){
          let dataDict = {};
          if(json){
            if(json.total === 0){
              return Promise.resolve();
            }
            else {
              let data = {};
              let item = json.entry[0];
              if(item.resource.component){
                let subdata = item.resource.component;
                for(let part of subdata){
                  if(part.code.coding[0].code === subcode){
                    data = part.valueQuantity;
                    data['date'] = item.resource.effectiveDateTime;
                    //TODO figure out if part.code.coding.text is different from part.code.coding[0].display
                    //apparently, some have text and others have display exclusively, for some ungodly reason.
                    if(!dataDict['name'])
                      dataDict['name'] = part.code.coding[0].display || part.code.text;
                    if(!dataDict['code']){
                      dataDict['code'] = part.code.coding[0].code;
                    }
                  }
                }
              }
              else {
                data = item.resource.valueQuantity;
                data['date'] = item.resource.effectiveDateTime;
                if(!dataDict['name'])
                  dataDict['name'] = item.resource.code.coding[0].display || item.resource.code.text;
                if(!dataDict['code']){
                  dataDict['code'] = item.resource.code.coding[0].code;
                }
              }
              //add our only data element as a list to the return dictionary
              dataDict['measurements'] = [data];
            }
          } 
          else {
            return Promise.resolve();
          }
        dispatch(receiveMostRecentObsByCode(patientID, code, dataDict));
        } 
      );
  };

}

export const FETCH_ALL_OBSERVATION_BY_CODE_REQUEST = 'FETCH_ALL_OBSERVATION_BY_CODE_REQUEST';
export const FETCH_ALL_OBSERVATION_BY_CODE_SUCCESS = 'FETCH_ALL_OBSERVATION_BY_CODE_SUCCESS';
export const FETCH_ALL_OBSERVATION_BY_CODE_FAILURE = 'FETCH_ALL_OBSERVATION_BY_CODE_FAILURE';


export const requestAllObsByCode = (patientID, code) => ({
  type: FETCH_ALL_OBSERVATION_BY_CODE_REQUEST,
  id: patientID,
  code: code
});

export const receiveAllObsByCode = (patientID, code, data) => ({
  type: FETCH_ALL_OBSERVATION_BY_CODE_SUCCESS,
  patientID,
  code: code,
  all_obs_by_code: data,
  receivedAt: Date.now()
});

export const failureAllObsByCode = () => ({
  type: FETCH_ALL_OBSERVATION_BY_CODE_FAILURE,
  receivedAt: Date.now()
});


export function shouldFetchAllObsByCode(state, code, subcode = null) {
  let currFetchingList = state.fhirObservationData.isFetchingAllMeasurementByCodeList;
  if( currFetchingList.hasOwnProperty(code) || currFetchingList.hasOwnProperty(subcode)) {
    return false;
  }

  let allMeasures = state.fhirObservationData.codeList;  
  for(let measure of allMeasures) {
    if(measure === code || measure === subcode){
      return false;
    }
  }    
  return true;
}

export function fetchAllObsByCode(patientID, code, subcode = null) {
  return (dispatch, getState) => {
    // checks if we need to fetch, only does so if there isn't already a request out or we don't have the measurement yet.
    if(!shouldFetchAllObsByCode(getState(), code, subcode)) {
      return Promise.resolve();
    }
    dispatch(requestAllObsByCode(patientID, code));
    const baseUrl = getURL();
    //sorted newest to oldest
    return fetch(baseUrl + '/Observation?subject=' + patientID + '&code=' + code + '&_sort=-date')
      .then(
        response => response.json(),
        error => {
          console.warn('An error occured when pulling observations by code :(', error)
          dispatch(failureAllObsByCode());
          return Promise.resolve();
        }
      )
      .then(function(json){
          let dataDict = {};
          let outputCode = "";
          if(json){
            if(json.total === 0){
              return Promise.resolve();
            }
            else {
              let dataList = [];
              for(let item of json.entry){
                let data = {};
                if(item.resource.component){
                  let subdata = item.resource.component;
                  for(let part of subdata){
                    if(part.code.coding[0].code === subcode){
                      data = part.valueQuantity;
                      data['date'] = item.resource.effectiveDateTime;
                      //TODO figure out if part.code.coding.text is different from part.code.coding[0].display
                      //apparently, some have text and others have display exclusively, for some ungodly reason.
                      if(!(dataDict.hasOwnProperty('name'))) {

                        dataDict['name'] = part.code.coding[0].display || part.code.text;
                      }
                      if(!dataDict.hasOwnProperty('code')){
                        dataDict['code'] = part.code.coding[0].code;
                        outputCode = part.code.coding[0].code;
                      }
                      break;
                    }
                  }
                }
                else {
                  data = item.resource.valueQuantity;
                  data['date'] = item.resource.effectiveDateTime;
                  if(!dataDict['name'])
                    dataDict['name'] = item.resource.code.coding[0].display || item.resource.code.text;
                  if(!dataDict['code']){
                    dataDict['code'] = item.resource.code.coding[0].code;
                    outputCode = item.resource.code.coding[0].code;
                  }
                }
                dataList.push(data);

              }

              dataList = sortByDate(dataList);
              dataDict['measurements'] = dataList;
            }
          }
        dispatch(receiveAllObsByCode(patientID, outputCode, dataDict));
      });
  };
}

export const FETCH_ALL_OBSERVATION_REQUEST = 'FETCH_ALL_OBSERVATION_REQUEST';
export const FETCH_ALL_OBSERVATION_SUCCESS = 'FETCH_ALL_OBSERVATION_SUCCESS';
export const FETCH_ALL_OBSERVATION_FAILURE = 'FETCH_ALL_OBSERVATION_FAILURE';


// we will get all the observations, regardless of code
export const requestAllObs = (patientID) => ({
  type: FETCH_ALL_OBSERVATION_REQUEST,
  patientID,
})

export const receiveAllObs = (patientID, data) => ({
  type: FETCH_ALL_OBSERVATION_SUCCESS,
  patientID,
  all_other_obs: data,
  receivedAt: Date.now()
})

export const failureAllObs = () => ({
  type: FETCH_ALL_OBSERVATION_FAILURE,
  receivedAt: Date.now()

})

// TODO: complete this after figuring out how to do an excluded list fetchAll from fhir.js or otherwise.
// use this queryObject: var queryDictPiece = {'subject':patientID, 'code': {'$and': [{'$not':'30522-7'}, {'$not':'20565-8'}, {'$not':'6298-4'}]}}
export function fetchAllObsExcluded(patientID, excludeCodeList) {
  return (dispatch, getState) => {
    dispatch(requestAllObs(patientID));

    
    const baseUrl = getURL();
    // we take all the LOINC codes that have already been collected, so we don't do it again and do the rest of them.
    // ex: https://fhirtest.uhn.ca/baseDstu3/Observation?subject=240225&code:not=30522-7&code:not=38483-4
    // NOTE: there is a very slight caveat that if you have more than 114 different codes that you want to include, the URL becomes too long for some browsers (specifically IE)
    let excludedCodeURL = baseUrl + '/Observation?subject=' + patientID;
    for (let code of excludeCodeList){
      excludedCodeURL += ('&code:not=' + code)
    }

    // var mkFhir = require('fhir.js');
    
    // var client = mkFhir({
    //   baseUrl: getURL()
    // });

    // client
    //   .fetchAll({type: 'Observation', query: {'subject':getPatID().toString()}})
    //   .then(function(res){
    //     var bundle = res.data;
    //     // var count = (bundle.entry && bundle.entry.length) || 0;
    //     console.log("# Patients born in 1974: ", res);
    //   })
    //   .catch(function(res){
    //     console.log("error res", res);
    //     //Error responses
    //     if (res.status){
    //         console.log('Error1', res.status);
    //     }

    //     //Errors
    //     if (res.message){
    //         console.log('Error1', res.message);
    //     }
    //   });


    return fetch(excludeCodeList)
      .then(
        response => response.json(),
        error => console.error('An error occured.', error)
      )
      .then(function(json){


      });


  }
}

export function fetchAllObs(patientID) {
  return (dispatch, getState) => {
    dispatch(requestAllObs(patientID));
    const baseUrl = getURL();

    var mkFhir = require('fhir.js');
    var client = mkFhir({
      baseUrl: getInsecureURL()
    });

    //sort by code
    client
      .fetchAll({type: 'Observation', query: {'subject':patientID, '$sort': [['code','asc']]}})
      .then(function(res){
        
        var bundle = aa
        //res;
        console.log(res + "SAFWGEWGWE")
        let currObsIdx = 0;
        let currObs = bundle[currObsIdx].code.coding[0].code;

        if(!bundle){
          return Promise.resolve();
        }
        var allObsList = [];

        // here we use another method to pull data elements out of the obs bundle and place them in the form of: 
        // [{"name": "xxxx", "code": "xxxx-xx", measurements": [{"value": 100, "date": 2017-08-12, "unit": mmHg}]}]
        allObsList = sortMeasurementsFromClient(bundle)
        // TODO: is there a right way to do this?
        let currState = getState();
        let currCodesCollected = currState.fhirObservationData.codeList;
        let allUncollectedMeasures = [];

        for(let measure of allObsList){
          if(currCodesCollected.indexOf(measure.code) < 0){
            measure['measurements'] = sortByDate(measure.measurements);
            allUncollectedMeasures.push(measure);
          }
        }

        dispatch(receiveAllObs(patientID, allUncollectedMeasures));
      })
      .catch(function(res){
        //Error responses
        dispatch(failureAllObs());
        return Promise.resolve();
        if (res.status){
        }

        //Errors
        if (res.message){
        }
      });
  }
}

const aa=
{
  "resourceType": "Bundle",
  "type": "transaction",
  "entry": [
    {
        "resource": {
        "resourceType": "Condition",
        "id": "194058",
        "meta": {
          "versionId": "1",
          "lastUpdated": "2017-07-27T15:38:45.870-04:00",
          "profile": [
            "http://standardhealthrecord.org/fhir/StructureDefinition/shr-problem-Problem"
          ]
        },
        "clinicalStatus": "active",
        "verificationStatus": "confirmed",
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "40917007",
              "display": "Confusion"
            }
          ],
          "text": "Confusion"
        },
        "subject": {
          "reference": "Patient/240225"
        },
                "onsetDateTime": "2013-07-28T02:08:13-04:00",
        "abatementDateTime": "2014-10-08T03:44:27-04:00",
        "assertedDate": "2013-07-28"
      },
      "request": {
        "method": "POST"
      }
    },
    {
        "resource": {
        "resourceType": "Condition",
        "id": "182484",
        "meta": {
          "versionId": "1",
          "lastUpdated": "2017-07-12T14:02:41.628-04:00",
          "profile": [
            "http://standardhealthrecord.org/fhir/StructureDefinition/shr-problem-Problem"
          ]
        },
        "clinicalStatus": "active",
        "verificationStatus": "confirmed",
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "68496003",
              "display": "Polyp of colon"
            }
          ],
          "text": "Polyp of colon"
        },
        "subject": {
          "reference": "Patient/240225"
        },
        "onsetDateTime": "2013-07-28T02:08:13-04:00",
        "abatementDateTime": "2014-10-08T03:44:27-04:00",
        "assertedDate": "2013-07-28"
      },
      "request": {
        "method": "POST"
      }
    },
    {
        "resource": {
        "resourceType": "Condition",
        "id": "182299",
        "meta": {
          "versionId": "1",
          "lastUpdated": "2017-07-12T14:02:41.628-04:00",
          "profile": [
            "http://standardhealthrecord.org/fhir/StructureDefinition/shr-problem-Problem"
          ]
        },
        "clinicalStatus": "active",
        "verificationStatus": "confirmed",
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "38341003",
              "display": "Hypertension"
            }
          ],
          "text": "Hypertension"
        },
        "subject": {
          "reference": "Patient/240225"
        },
        "onsetDateTime": "1997-07-02T19:55:15-04:00",
        "assertedDate": "1997-07-02"
      },
      "request": {
        "method": "POST"
      }
    },
    {
        "resource": {
        "resourceType": "Condition",
        "id": "182516",
        "meta": {
          "versionId": "1",
          "lastUpdated": "2017-07-12T14:02:41.628-04:00",
          "profile": [
            "http://standardhealthrecord.org/fhir/StructureDefinition/shr-problem-Problem"
          ]
        },
        "clinicalStatus": "active",
        "verificationStatus": "confirmed",
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "10509002",
              "display": "Acute bronchitis (disorder)"
            }
          ],
          "text": "Acute bronchitis (disorder)"
        },
        "subject": {
          "reference": "Patient/240225"
        },
        "onsetDateTime": "2013-10-08T01:25:44-04:00",
        "abatementDateTime": "2013-10-22T20:34:31-04:00",
        "assertedDate": "2013-10-08"
      },
      "request": {
        "method": "POST"
      }
    },
    {
        "resource": {
        "resourceType": "Condition",
        "id": "182302",
        "meta": {
          "versionId": "1",
          "lastUpdated": "2017-07-12T14:02:41.628-04:00",
          "profile": [
            "http://standardhealthrecord.org/fhir/StructureDefinition/shr-problem-Problem"
          ]
        },
        "clinicalStatus": "active",
        "verificationStatus": "confirmed",
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "368581000119106",
              "display": "Neuropathy due to type 2 diabetes mellitus (disorder)"
            }
          ],
          "text": "Neuropathy due to type 2 diabetes mellitus (disorder)"
        },
        "subject": {
          "reference": "Patient/240225"
        },
        "onsetDateTime": "2005-02-26T06:59:19-05:00",
        "assertedDate": "2005-02-26"
      },
      "request": {
        "method": "POST"
      }
    },
    {
        "resource": {
        "resourceType": "Condition",
        "id": "182422",
        "meta": {
          "versionId": "1",
          "lastUpdated": "2017-07-12T14:02:41.628-04:00",
          "profile": [
            "http://standardhealthrecord.org/fhir/StructureDefinition/shr-problem-Problem"
          ]
        },
        "clinicalStatus": "active",
        "verificationStatus": "confirmed",
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "33737001",
              "display": "Fracture of rib"
            }
          ],
          "text": "Fracture of rib"
        },
        "subject": {
          "reference": "Patient/240225"
        },
        "onsetDateTime": "2010-11-14T09:03:28-05:00",
        "abatementDateTime": "2011-01-03T13:55:21-05:00",
        "assertedDate": "2010-11-14"
      },
      "request": {
        "method": "POST"
      }
    },
    {
        "resource": {
        "resourceType": "Condition",
        "id": "182301",
        "meta": {
          "versionId": "1",
          "lastUpdated": "2017-07-12T14:02:41.628-04:00",
          "profile": [
            "http://standardhealthrecord.org/fhir/StructureDefinition/shr-problem-Problem"
          ]
        },
        "clinicalStatus": "active",
        "verificationStatus": "confirmed",
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "15777000",
              "display": "Prediabetes"
            }
          ],
          "text": "Prediabetes"
        },
        "subject": {
          "reference": "Patient/240225"
        },
        "onsetDateTime": "2003-04-07T20:15:28-04:00",
        "assertedDate": "2003-04-07"
      },
      "request": {
        "method": "POST"
      }
    },
    {
        "resource": {
        "resourceType": "Condition",
        "id": "182300",
        "meta": {
          "versionId": "1",
          "lastUpdated": "2017-07-12T14:02:41.628-04:00",
          "profile": [
            "http://standardhealthrecord.org/fhir/StructureDefinition/shr-problem-Problem"
          ]
        },
        "clinicalStatus": "active",
        "verificationStatus": "confirmed",
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "44054006",
              "display": "Diabetes"
            }
          ],
          "text": "Diabetes"
        },
        "subject": {
          "reference": "Patient/240225"
        },
        "onsetDateTime": "1997-07-02T19:55:15-04:00",
        "assertedDate": "1997-07-02"
      },
      "request": {
        "method": "POST"
      }
    },
    {
        "resource": {
        "resourceType": "Condition",
        "id": "182548",
        "meta": {
          "versionId": "1",
          "lastUpdated": "2017-07-12T14:02:41.628-04:00",
          "profile": [
            "http://standardhealthrecord.org/fhir/StructureDefinition/shr-problem-Problem"
          ]
        },
        "clinicalStatus": "active",
        "verificationStatus": "confirmed",
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "444814009",
              "display": "Viral sinusitis (disorder)"
            }
          ],
          "text": "Viral sinusitis (disorder)"
        },
        "subject": {
          "reference": "Patient/240225"
        },
        "onsetDateTime": "2015-06-04T05:48:13-04:00",
        "abatementDateTime": "2015-07-07T01:54:46-04:00",
        "assertedDate": "2015-06-04"
      },
      "request": {
        "method": "POST"
      }
    },
    {
        "resource": {
        "resourceType": "Condition",
        "id": "182304",
        "meta": {
          "versionId": "1",
          "lastUpdated": "2017-07-12T14:02:41.628-04:00",
          "profile": [
            "http://standardhealthrecord.org/fhir/StructureDefinition/shr-problem-Problem"
          ]
        },
        "clinicalStatus": "active",
        "verificationStatus": "confirmed",
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "127013003",
              "display": "Diabetic renal disease (disorder)"
            }
          ],
          "text": "Diabetic renal disease (disorder)"
        },
        "subject": {
          "reference": "Patient/240225"
        },
        "onsetDateTime": "2006-02-28T09:17:22-05:00",
        "assertedDate": "2006-02-28"
      },
      "request": {
        "method": "POST"
      }
    },
    {
        "resource": {
        "resourceType": "Condition",
        "id": "182303",
        "meta": {
          "versionId": "1",
          "lastUpdated": "2017-07-12T14:02:41.628-04:00",
          "profile": [
            "http://standardhealthrecord.org/fhir/StructureDefinition/shr-problem-Problem"
          ]
        },
        "clinicalStatus": "active",
        "verificationStatus": "confirmed",
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "53741008",
              "display": "Coronary Heart Disease"
            }
          ],
          "text": "Coronary Heart Disease"
        },
        "subject": {
          "reference": "Patient/240225"
        },
        "onsetDateTime": "2006-02-28T09:17:22-05:00",
        "assertedDate": "2006-02-28"
      },
      "request": {
        "method": "POST"
      }
    },
    {
        "resource": {
        "resourceType": "Condition",
        "id": "182430",
        "meta": {
          "versionId": "1",
          "lastUpdated": "2017-07-12T14:02:41.628-04:00",
          "profile": [
            "http://standardhealthrecord.org/fhir/StructureDefinition/shr-problem-Problem"
          ]
        },
        "clinicalStatus": "active",
        "verificationStatus": "confirmed",
        "code": {
          "coding": [
            {
              "system": "http://snomed.info/sct",
              "code": "65363002",
              "display": "Otitis media"
            }
          ],
          "text": "Otitis media"
        },
        "subject": {
          "reference": "Patient/240225"
        },
        "onsetDateTime": "2011-05-10T21:13:54-04:00",
        "abatementDateTime": "2011-06-05T12:01:56-04:00",
        "assertedDate": "2011-05-10"
      },
      "request": {
        "method": "POST"
      }
    }
  ]
}