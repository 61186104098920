import * as actionTypes from '../actions/types';
let initialState ={
    hsptl: null,
    role:null,
    loading: false,
    error: null,
}
export default (state = initialState, action) => {
    state = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.HOSPITAL_SELECT_START:
            state.error = null;
            state.loading = true;
            break;
        case actionTypes.HOSPITAL_SELECT_SUCCESS:
            state.hsptl=action.hsptl
            state.role=action.role
            state.loading = false;
            break;
        case actionTypes.HOSPITAL_SELECT_FAIL:
            state.error = action.error;
            state.loading = false;
            break;
    }
    return state;
};
