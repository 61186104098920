import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
//import { authRoles } from '../../auth/authRoles'
const Allergies = Loadable(lazy(() => import('./Allergies')))
const Immunizations = Loadable(lazy(() => import('./Immunizations')))
const Medications = Loadable(lazy(() => import('./Medications')))
const Problems = Loadable(lazy(() => import('./Problems')))
const Observation= Loadable(lazy(() => import('./observation')))
const MedicationRequest= Loadable(lazy(() => import('./MedicationRequest')))
const Labs= Loadable(lazy(() => import('./Labs')))
const Radiology= Loadable(lazy(() => import('./Radiology')))
const Pathology= Loadable(lazy(() => import('./pathology')))
const Disease= Loadable(lazy(() => import('./DiabetesManagement')))
const PreventToCare= Loadable(lazy(() => import('./preventtocare')))
const Diseasemanagement= Loadable(lazy(() => import('./DieasemgmtTabs')))
const PatientTabs= Loadable(lazy(() => import('./Patient360Tabs')))
const PatientRoutes = [
    
    {
        path: '/medicationrequest',
        element: <MedicationRequest />,
        //auth: authRoles.admin,
    },
    {
        path: '/allergies',
        element: <Allergies />,
        //auth: authRoles.admin,
    },
    {
        path: '/immunizations',
        element: <Immunizations />,
        // auth: authRoles.admin,
    },
    {
        path: '/medications',
        element: <Medications />,
        // auth: authRoles.admin,
    },
    {
        path: '/problems',
        element: <Problems />,
        // auth: authRoles.admin,
    },
    {
        path: '/observation',
        element: <Observation />,
        // auth: authRoles.admin,
    },
    {
        path: '/labs',
        element: <Labs />,
        // auth: authRoles.admin,
    },
    {
        path: '/radiology',
        element: <Radiology />,
        // auth: authRoles.admin,
    },
    {
        path: '/pathology',
        element: <Pathology />,
        // auth: authRoles.admin,
    },
    
    {
        path: '/diseasemanagement',
        element: <Disease />,
        // auth: authRoles.admin,
    },
    {
        path: '/preventativecare',
        element: <PreventToCare />,
        // auth: authRoles.admin,
    },
    {
        path: '/diseasemgmt',
        element: <Diseasemanagement />,
        // auth: authRoles.admin,
    },
    {
        path: '/patient360',
        element: <PatientTabs />,
        // auth: authRoles.admin,
    },
    
    
]

export default PatientRoutes
