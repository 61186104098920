import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const NotFound = Loadable(lazy(() => import("./NotFound")));
const ForgotPassword = Loadable(lazy(() => import("./ForgotPassword")));
const JwtLogin = Loadable(lazy(() => import("./login/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("./register/JwtRegister")));
const Start = Loadable(lazy(() => import("./start")));
const AfterAuth = Loadable(lazy(() => import("./afterauth")));
const LandingPage =Loadable(lazy(()=>import("../start/index")))
const sessionRoutes = [
    {
        path: '/',
        element: <LandingPage />,
    },
    {
        path: '/start',
        element: <Start />,
    },
    {
        path: '/auth/hospitals',
        element: <AfterAuth />,
    },
    {
        path: '/session/signup',
        element: <JwtRegister />,
    },
    {
        path: '/session/signin',
        element: <JwtLogin />,
    },
    {
        path: '/session/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/session/404',
        element: <NotFound />,
    },
]

export default sessionRoutes
