import * as actionTypes from '../actions/types';
let initialState ={
    sandbox: null,
    loading: false,
    error: null,
}
export default (state = initialState, action) => {
    state = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.SANDBOX_LOAD_START:
            state.error = null;
            state.loading = true;
            break;
        case actionTypes.SANDBOX_LOAD_SUCCESS:
            state.sandbox=action.sandbox
            state.loading = false;
            break;
        case actionTypes.SANDBOX_LOAD_FAIL:
            state.error = action.error;
            state.loading = false;
            break;
          default: {
                return state
            }
        // default: {
        //     return [...state]
        // }
    }
    return state;
};
// switch (action.type) {
//     case SET_USER_NAVIGATION: {
//         return [...action.payload]
//     }
//     default: {
//         return [...state]
//     }
// }