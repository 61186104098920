// createuser.js

import { get_user_Appointment_data } from '../actions/getUserAppointmentData';

const initialState = {
  data: null,
};

const getUserAppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case get_user_Appointment_data:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default getUserAppointmentReducer;
