import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import RootReducer from './reducers/RootReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { createTransform } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
//const initialState = {}
//const middlewares = [thunk]
let devtools = (x) => x


const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // convert mySet to an Array.
    return { ...inboundState, mySet: [...inboundState.mySet] };
  },
  // transform state being rehydrated
  (outboundState, key) => {
    // convert mySet back to a Set.
    return { ...outboundState, mySet: new Set(outboundState.mySet) };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['someReducer'] }
);
const persistConfig = {
    key: 'root',
    storage,
 //whitelist: ["users", "sandbox"]
 transforms: [SetTransform],
   //stateReconciler: hardSet,///hide this one if redux not updates
  }
   
if (
    process &&
    process.env.NODE_ENV !== 'production' &&
    process.browser &&
    window.__REDUX_DEVTOOLS_EXTENSION__
) {
    devtools = window.__REDUX_DEVTOOLS_EXTENSION__()
}
const persistedReducer = persistReducer(persistConfig, RootReducer)
// export const Store = createStore(
//     RootReducer,
//     initialState,
//     compose(applyMiddleware(...middlewares), devtools)
// )

// const loadState = () => {
//     try {
//       const serializedState = localStorage.getItem('state');
//       if(serializedState === null) {
//         return undefined;
//       }
//       return JSON.parse(serializedState);
//     } catch (e) {
//       return undefined;
//     }
//   };
  
//   const saveState = (state) => {
//     try {
//       const serializedState = JSON.stringify(state);
//       localStorage.setItem('state', serializedState);
//     } catch (e) {
//       // Ignore write errors;
//     }
//   };
  
//   const peristedState = loadState();
  
//   store.subscribe(() => {
//     saveState(store.getState());
//   });
  

let store = createStore(persistedReducer, {}, compose(applyMiddleware(thunk), devtools))
    let persistor = persistStore(store)
    export default store
    export {persistor}
// export default () => {
//    /// let store = createStore(persistedReducer, {}, compose(applyMiddleware(thunk), devtools))
    
//     return { store, persistor }
//   }
