import '../fake-db'
import React from 'react'
//import { Store } from './redux/Store'
//import { Provider } from 'react-redux'
import { AllPages } from './routes/routes'
import { MatxTheme } from 'app/components'
import { useRoutes } from 'react-router-dom'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
// import configureStore from './redux/Store'
// import { PersistGate } from 'redux-persist/integration/react'
// const { persistor, store } = configureStore()
//hide app.scss file if you don't use lforms because lform override our custom styles
import './app.scss'
const App = () => {
    const all_pages = useRoutes(AllPages())
    React.useEffect(() => {
        window.chatwootSettings = {
            hideMessageBubble: false,
            position: 'right', // This can be left or right
            locale: 'en', // Language to be set
            type: 'standard', // [standard, expanded_bubble]
        }
        ;(function (d, t) {
            var BASE_URL = 'https://app.chatwoot.com'
            var g = d.createElement(t),
                s = d.getElementsByTagName(t)[0]
            g.src = BASE_URL + '/packs/js/sdk.js'
            g.defer = true
            g.async = true
            s.parentNode.insertBefore(g, s)
            g.onload = function () {
                window.chatwootSDK.run({
                    websiteToken: 'oBC4zW1pRp9LFuhbtg26Bhfx',
                    baseUrl: BASE_URL,
                })
            }
        })(document, 'script')
    }, [])
    React.useEffect(() => {
        window.process = {
          ...window.process,
        };
      }, []);
    return (
        <SettingsProvider>
            <MatxTheme>
                <AuthProvider>{all_pages}</AuthProvider>
            </MatxTheme>
        </SettingsProvider>
    )
}

export default App


