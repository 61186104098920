import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'
const Contacts = Loadable(lazy(() => import('./contacts')))
const Email = Loadable(lazy(() => import('./Email')))
const Messages = Loadable(lazy(() => import('./Messages')))
const Vedio = Loadable(lazy(() => import('./vedio')))
const Communication = Loadable(lazy(() => import('./communicationtabs')))
const commRoutes = [
    {
        path: '/email',
        element: <Email />,
        auth: authRoles.admin,
    },
    {
        path: '/messages',
        element: <Messages />,
        // auth: authRoles.admin,
    },
    {
        path: '/video',
        element: <Vedio />,
        // auth: authRoles.admin,
    },
    {
        path: '/contacts',
        element: <Contacts />,
        // auth: authRoles.admin,
    },
    {
        path: '/communication',
        element: <Communication />,
        // auth: authRoles.admin,
    },
]

export default commRoutes
